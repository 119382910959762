<template lang="pug">
.stats.fixed
  span.fps(ref='stats') 0
  span.p {{ animation.p }}
.p-controller.absolute(ref='pController')
canvas.canvas(ref='canvas', @click='onClick')
  img(ref='img', src='~@/assets/star.png')
.tips.fixed
  span 点击/下划
</template>

<script>
window.__DEBUG__ = false;
import createREGL from 'regl';
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';
import mat4 from 'gl-mat4';
import SphereMesh from 'icosphere';

import { addScrollListener } from '@zhinan-oppo/scroll-handle';

import vert from '@/shaders/stars.vert';
import frag from '@/shaders/stars.frag';

const TRAIL_L = 40;
const N_COL = 200;
const N_ROW = 6;
const OFFSET_R = [200, 250];

function prepareMesh(sphere) {
  const N = 6;

  const positions = [...sphere.positions];
  const cells = [...sphere.cells];
  const roles = sphere.positions.map(() => 0);

  const indexStart = positions.length;
  const R = 0.8;
  for (let i = 0; i < N; i += 1) {
    const alpha = (i * 2 * Math.PI) / N;
    const x = R * Math.cos(alpha);
    const y = R * Math.sin(alpha);

    positions.push([x, y, 1]);
    positions.push([x, y, -TRAIL_L + 1]);
    cells.push(
      [indexStart + i, indexStart + i + 1, indexStart + 2 * ((i + 1) % N)],
      [indexStart + i, indexStart + i + 1, indexStart + 2 * ((i + 1) % N) + 1],
    );
    roles.push(1, 1);
  }

  const typedPositions = new Float32Array(positions.length * 3);
  positions.forEach((position, i) => {
    typedPositions.set(position, i * 3);
  });
  const typedCells = new Int8Array(cells.length * 3);
  cells.forEach((cell, i) => {
    typedCells.set(cell, i * 3);
  });

  return {
    positions: typedPositions,
    cells: typedCells,
    roles: new Int8Array(roles),
  };
}

export default {
  name: 'Stars',
  setup() {
    const sphereMesh = SphereMesh(0);
    const mesh = prepareMesh(sphereMesh);

    const canvas = ref(null);
    const img = ref(null);
    const stats = ref(null);
    const pController = ref(null);

    const DURATION = 8;
    const animation = reactive({
      trailState: 0,
      duration: DURATION,
      z: 800,
      timeScale: 0.5,
      p: 0,
    });

    let state = 0;
    const onClick = () => {
      state = (state + 1) % 3;
      switch (state) {
        case 0:
          animation.timeScale = 0.5;
          animation.trailState = 0;
          break;
        case 1:
          animation.timeScale = 2;
          animation.trailState = 1;
          break;
        case 2:
          animation.timeScale = 1;
          animation.trailState = 2;
          break;
        default:
          break;
      }
    };
    // window.setInterval(onClick, 10 * 1000);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    onMounted(() => {
      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      addScrollListener(pController.value, {
        start: 'top',
        end: 'bottom',
        forceInViewBoundary: true,
        handlers: {
          inView({ distance, total }) {
            animation.p = distance / total;
          },
        },
      });

      regl = createREGL({
        canvas: canvas.value,
        extensions: ['angle_instanced_arrays'],
      });

      // 单个星星的顶点位置
      const pointBuffer = regl.buffer({ data: mesh.positions, type: 'float32' });

      // 各个星星位置相对原点的偏移
      const z = -animation.z;
      const R = OFFSET_R.reduce((a, b) => a + b) / OFFSET_R.length;
      const lineOffsets = [];
      for (let i = 0; i <= N_COL; i += 1) {
        const x = ((i % N_COL) / N_COL - 0.5) * 2;
        for (let j = 0; j < N_ROW; j += 1) {
          lineOffsets.push([x * R, 0, z / 2]);
        }
      }
      const ringOffsets = Array(N_ROW * N_COL)
        .fill()
        .map(() => {
          const r = Math.random() * (OFFSET_R[1] - OFFSET_R[0]) + OFFSET_R[0];
          const theta = Math.random() * 2 * Math.PI;
          const x = r * Math.cos(theta);
          const y = r * Math.sin(theta);
          return [x, y, z];
        });
      const ringOffsetBuffer = regl.buffer(ringOffsets);
      const ringView = mat4.lookAt([], [0, 0, 10], [0, 0, 0], [0, 1, 0]);

      const lineOffsetBuffer = regl.buffer(lineOffsets);
      const lineView = mat4.lookAt([], [0, 200, 0], [0, 0, 0], [0, 0, -1]);

      let time = 0;
      const draw = regl({
        vert,
        frag,
        context: {
          projection: ({ viewportWidth, viewportHeight }) =>
            mat4.perspective([], (Math.PI * 2) / 3, viewportWidth / viewportHeight, 0.01, 1000),
          eye: ({ tick }) => {
            // const t = 0.001 * tick;
            // return [4, 0, 0];
            return [0, 0, 10];
            // return [3, 200 * Math.cos(t), 200 * Math.sin(t)];
          },
        },
        uniforms: {
          projection: ({ projection }) => projection,
          model: mat4.identity([]),
          ringView: ringView,
          lineView: lineView, // ({ eye }) => mat4.lookAt([], eye, [0, 0, 0], [0, 1, 0]), // () => camera.view(),
          time: () => (time += 0.016 * animation.timeScale),
          trailLen: TRAIL_L,
          'animation.z': animation.z,
          'animation.duration': () => animation.duration,
          'animation.trailState': () => animation.trailState,
          'animation.p': () => animation.p,
        },
        attributes: {
          position: pointBuffer,
          role: mesh.roles,
          ringOffset: {
            buffer: ringOffsetBuffer,
            divisor: 1,
          },
          lineOffset: {
            buffer: lineOffsetBuffer,
            divisor: 1,
          },
        },
        elements: mesh.cells,
        instances: N_ROW * N_COL,
        primitive: 'triangles',
        depth: { enable: false },
        blend: {
          enable: true,
          func: {
            srcRGB: 'src alpha',
            srcAlpha: 'src alpha',
            dstRGB: 'one minus src alpha',
            dstAlpha: 'one minus src alpha',
          },
        },
      });

      let t;
      let c = 0;
      const update = ({ time }) => {
        draw();
        // regl.clear({ color: [0, 0, 0, 1] });
        // console.log(draw.stats);

        if (!t) {
          t = time;
        }
        c += 1;
        if (c >= 60) {
          const fps = c / (time - t);
          c = 0;
          t = time;
          stats.value.textContent = fps;
        }

        // camera.tick();
      };
      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img, stats, animation, pController, onClick };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.p-controller
  width 100%
  height 500vh

.canvas
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color black
  cursor pointer

  img
    display none

.tips
  width 100%
  display flex
  align-items flex-end
  justify-content center
  pointer-events none
  top auto
  bottom 0
  color yellow
</style>
